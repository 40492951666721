(function () {
  'use strict';

  class DiscussionCardCtrl {
    constructor($state, $rootScope) {
      let vm = this;
      vm.ctrlName = 'DiscussionCardCtrl';
      vm.$state = $state;
      vm.$rootScope = $rootScope;
    }
    go(url, params) {
      let vm = this;
      vm.$state.go(url, params);
    }
  }

  /**
   * @ngdoc object
   * @name discussions.index.controller:DiscussionCardCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('DiscussionCardCtrl', DiscussionCardCtrl);
}());
